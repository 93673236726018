<template>
  <div class='_404'>
    <el-result icon="error" title="404" subTitle="抱歉，您没有权限访问该页面或该页面不存在。">
      <template slot="extra">
        <el-button type="primary" size="medium" plain @click="goLogin">返回</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>

export default {
  data () {
    return {
      code: '',
    }
  },
  created () {
    this.code = this.$route.query.code
  },
  methods: {
    goLogin () {
      this.$store.dispatch('logout')
      this.$router.replace('/login')
    },
  },
}
</script>

<style scoped lang='scss'>
._404 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
</style>
